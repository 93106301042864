@font-face {
    font-family: 'Replica Bold';
    src: local('Replica Bold'), url(../fonts/Replica-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'Replica Regular';
    src: local('Replica Regular'), url(../fonts/Replica-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Replica Light';
    src: local('Replica Light'), url(../fonts/Replica-Light.ttf) format('truetype');
}
@font-face {
    font-family: 'Inter Bold';
    src: local('Inter Bold'), url(../fonts/Inter/Inter-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'Inter Semi Bold';
    src: local('Inter Semi Bold'), url(../fonts/Inter/Inter-SemiBold.ttf) format('truetype');
}
@font-face {
    font-family: 'Inter Medium';
    src: local('Inter Medium'), url(../fonts/Inter/Inter-Medium.ttf) format('truetype');
}
@font-face {
    font-family: 'Inter Regular';
    src: local('Inter Regular'), url(../fonts/Inter/Inter-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Inter Light';
    src: local('Inter Light'), url(../fonts/Inter/Inter-Light.ttf) format('truetype');
}
@font-face {
    font-family: 'ITC Avant Garde Gothic Pro';
    src: local('ITC Avant Garde Gothic Pro'), url(../fonts/ITCAvantGardePro/ITCAvantGardePro-Bold.ttf) format('truetype');
}